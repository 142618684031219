import React from 'react'
import BlockText from './block-text'
import ImageSlider from './imageSlider'
import 'react-slideshow-image/dist/styles.css'
import { Link } from 'gatsby'
import classNames from 'classnames'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import { Fade } from 'react-awesome-reveal'
import Sticky from 'react-stickynode'
import { isMobile } from 'react-device-detect'

class TextImageSlider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      widescreen: null,
    }
    this.scrollOutHeadline = this.scrollOutHeadline.bind(this)
    this.onResize = this.onResize.bind(this)
  }

  scrollOutHeadline = () => {
    const { title, headlineText } = this.props.textImageSlider
    const containerId = title && title.toLowerCase()
    if (containerId && headlineText) {
      const el = document.getElementById(containerId)
      const info = document.getElementById(`${containerId}-info-text`)
      const infoHeight = info.offsetHeight
      const scrollPoint = infoHeight - 34
      if (el) {
        if (this.state.widescreen) {
          if (window.scrollY > el.offsetTop + scrollPoint) {
            el.classList.add('is-scrolled')
          } else {
            el.classList.remove('is-scrolled')
          }
        } else {
          el.classList.remove('is-scrolled')
        }
      }
    }
  }

  onResize = () => {
    this.setState({
      widescreen: window.innerWidth >= 1920,
    })
  }

  render() {
    const {
      title,
      headlineText,
      text,
      sliderImages,
      buttonText,
      internalLink,
      externalLink,
      isAuto,
    } = this.props.textImageSlider
    const classes = classNames({
      'has-button': buttonText && internalLink,
    })
    const containerId = title.toLowerCase()
    const { widescreen } = this.state

    return (
      <div className={`text-image-slider columns is-marginless ${classes}`} id={containerId}>
        <div className="text-image-slider__mobile-nav-title text-image-slider__mobile-nav-title--project is-size-7">
          {title}
        </div>
        <div className="text-image-slider__text column is-flex">
          <Sticky enabled={!isMobile && widescreen} top={84} activeClass="is-sticky">
            {headlineText &&
              headlineText.map(textBlock => (
                <div
                  className="text-image-slider__headline-text--desktop is-size-3"
                  key={textBlock._key}
                  id={`${containerId}-headline-text`}
                >
                  <BlockText blocks={textBlock} />
                </div>
              ))}
          </Sticky>
          <div className="text-image-slider__body">
            <div
              className="text-image-slider__info-text is-size-5 is-size-6-mobile"
              id={`${containerId}-info-text`}
            >
              {text.map((textBlock, i) => (
                <Fade duration={400} triggerOnce key={i}>
                  <BlockText blocks={textBlock} />
                </Fade>
              ))}
            </div>
            {buttonText && internalLink && (
              <Fade duration={400} triggerOnce>
                <div className="text-image-slider__link">
                  <Link to={`/${internalLink._rawSlug.current}`} className="btn">
                    {buttonText}
                  </Link>
                </div>
              </Fade>
            )}
            {buttonText && externalLink && (
              <Fade duration={400} triggerOnce>
                <div className="text-image-slider__link">
                  <a className="btn" href={externalLink} target="_blank" rel="noopener noreferrer">
                    {buttonText}
                  </a>
                </div>
              </Fade>
            )}
          </div>
        </div>
        <div className="text-image-slider__slider column">
          <div className="text-image-slider__mobile-nav-title text-image-slider__mobile-nav-title--page is-size-7">
            {title}
          </div>
          {headlineText &&
            headlineText.map((textBlock, i) => (
              <Fade duration={400} triggerOnce key={i}>
                <div className="text-image-slider__headline-text--mobile is-size-4">
                  <BlockText blocks={textBlock} key={textBlock._key} />
                </div>
              </Fade>
            ))}
          {sliderImages.length && sliderImages.length > 1 ? (
            <Fade duration={400} triggerOnce>
              <ImageSlider images={sliderImages} inTextImageSlider isAuto={isAuto} />
            </Fade>
          ) : (
            <Fade duration={400} triggerOnce>
              <div className="text-image-slider__single-image">
                <div
                  className="blend-filter-bg-image"
                  style={{
                    backgroundImage: sliderImages[0].asset
                      ? `url(${imageUrlFor(buildImageObj(sliderImages[0]))
                          .width(1000)
                          .height(1000)
                          .url()})`
                      : 'none',
                  }}
                />
              </div>
            </Fade>
          )}
        </div>
      </div>
    )
  }
  componentDidMount() {
    if (typeof window === 'undefined') {
      return
    }
    this.onResize()
    const checkScrollSpeed = (function(settings) {
      settings = settings || {}
      let lastPos
      let newPos
      let timer
      let delta
      const delay = settings.delay || 50
      function clear() {
        lastPos = null
        delta = 0
      }
      clear()
      return () => {
        newPos = window.scrollY
        if (lastPos != null) {
          delta = newPos - lastPos
        }
        lastPos = newPos
        clearTimeout(timer)
        timer = setTimeout(clear, delay)
        return delta
      }
    })()
    window.onscroll = () => {
      let scrollSpeed = checkScrollSpeed()
      if (scrollSpeed >= 60) {
        document.body.classList.add('fast-scroll')
      } else {
        document.body.classList.remove('fast-scroll')
      }
    }
    window.addEventListener('scroll', this.scrollOutHeadline)
    window.addEventListener('resize', this.onResize)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollOutHeadline)
    window.removeEventListener('resize', this.onResize)
  }
}

export default TextImageSlider
