import React from 'react'
import { Link } from 'gatsby'
import Modal from 'react-modal'
import FormOverlay from './formOverlay'

const LinksDownloads = props => {
  const { linksDownloads } = props
  const links = linksDownloads ? linksDownloads.links || linksDownloads : null
  const getLinkStr = link => {
    const anchor = link.anchor
    if (link.pageLink) {
      if (link.pageLink.slug.current === 'startseite') {
        return anchor ? `/#${anchor}` : `/`
      } else {
        return anchor
          ? `/${link.pageLink.slug.current}/#${anchor}`
          : `/${link.pageLink.slug.current}`
      }
    } else if (link.projectLink) {
      return anchor ? `/${link.pageLink.slug.current}/#${anchor}` : `/${link.pageLink.slug.current}`
    }
  }

  return (
    <div className="links-downloads">
      {links &&
        links.map(link => {
          if (link.pageLink !== undefined)
            return (
              <div key={link._key}>
                <Link className="btn" to={getLinkStr(link)}>
                  {link.linkText}
                </Link>
              </div>
            )
          else if (link.projectLink !== undefined) {
            return (
              <div key={link._key}>
                <Link className="btn" to={getLinkStr(link)}>
                  {link.linkText}
                </Link>
              </div>
            )
          } else if (link.contactLink !== undefined) {
            return (
              <div key={link._key}>
                <a className="btn" href={`mailto:${link.contactLink}`}>
                  {link.linkText}
                </a>
              </div>
            )
          } else if (link.externalLinkUrl !== undefined) {
            return (
              <div key={link._key}>
                <a
                  className="btn"
                  href={link.externalLinkUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link.linkText}
                </a>
              </div>
            )
          } else if (link.download !== undefined) {
            return (
              <div key={link._key}>
                <a
                  className="btn"
                  href={link.download.file.asset.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  ↓ {link.linkText}
                </a>
              </div>
            )
          } else if (link.overlayLink !== undefined) {
            if (link.overlayLink === 'grundstueck-anbieten') {
              return (
                <div key={link._key}>
                  <FormOverlay linkText={link.linkText} overlayContent={link.overlayLink} />
                </div>
              )
            } else if (link.overlayLink === 'newsletter-signup') {
              return (
                <div key={link._key}>
                  <FormOverlay linkText={link.linkText} overlayContent={link.overlayLink} />
                </div>
              )
            }
          }
        })}
    </div>
  )
}

export default LinksDownloads
