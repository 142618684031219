import React from 'react'
import 'react-slideshow-image/dist/styles.css'
import { Fade } from 'react-slideshow-image'
import { buildImageObj, isBrowser, isRetina } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import MouseTooltip from 'react-sticky-mouse-tooltip'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import _debounce from 'lodash.debounce'

class ImageSlider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMouseOverImage: false,
      isMouseTooltipVisible: false,
      imgWidth: 2400,
    }
    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseOver = this.onMouseOver.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
    this.slideRef = React.createRef()
    this.back = this.back.bind(this)
    this.next = this.next.bind(this)
  }

  onResize = () => {
    const imgWidth = this.getImgWidth()
    this.setState({
      imgWidth: imgWidth,
    })
  }

  getImgWidth = () => {
    const { inTextImageSlider } = this.props
    let imgWidth
    if (isBrowser()) {
      const windowWidth = window.innerWidth
      if (inTextImageSlider) {
        if (windowWidth >= 1024) {
          imgWidth = Math.floor(windowWidth * 0.53)
        } else {
          imgWidth = windowWidth
        }
      } else {
        imgWidth = windowWidth
      }
      if (isRetina()) {
        imgWidth = imgWidth * 2
      }
      return Math.floor(imgWidth)
    } else {
      return 2400
    }
  }

  onMouseEnter(e) {
    // this.updateTooltipArrow(e)
    this.setState({ isMouseOverImage: true })
  }

  onMouseOver(e) {
    // this.updateTooltipArrow(e)
  }

  onMouseLeave() {
    this.setState({ isMouseOverImage: false })
  }

  back() {
    this.slideRef.current.goBack()
  }

  next() {
    this.slideRef.current.goNext()
  }

  render() {
    const { images, isFullWidthSlider, isAuto } = this.props
    const singleImage = images.length === 1
    const classes = classNames({
      'is-mouse-over-image': this.state.isMouseOverImage,
      'is-single-image': singleImage,
    })
    const imgWidth = this.state.imgWidth

    return (
      <div className={`image-slider ${classes}`}>
        <div className="slide-container image-slider__slides">
          <Fade
            ref={this.slideRef}
            arrows={false}
            autoplay={isAuto}
            duration={4000}
            transitionDuration={400}
          >
            {images &&
              images.map((image, i) => {
                const imgUrl = isFullWidthSlider
                  ? imageUrlFor(buildImageObj(image))
                      .width(imgWidth)
                      .height(Math.floor((9 / 16) * imgWidth))
                      .url()
                  : imageUrlFor(buildImageObj(image))
                      .width(imgWidth)
                      .url()
                return (
                  <div
                    className="each-fade image-slider__slide"
                    onClick={!singleImage && this.next}
                    key={i}
                  >
                    <div className="image-slider__slide-inner image-container">
                      <div
                        className="image-slider__bg-image"
                        style={{ backgroundImage: `url(${imgUrl})`, maxHeight: `600px` }}
                        onMouseEnter={this.onMouseEnter}
                        onMouseLeave={this.onMouseLeave}
                        role="img"
                        aria-label={image.alt ? image.alt : ''}
                      />
                      {!singleImage && (
                        <MouseTooltip visible={this.state.isMouseOverImage}>
                          <span className={`slider-mouse-tooltip`}>→</span>
                        </MouseTooltip>
                      )}
                    </div>
                    <div
                      className={`image-slider__info is-size-7 ${image.title ? 'has-title' : ''}`}
                    >
                      {image.title && <span className="image-slider__title">{image.title}</span>}
                      <span className="image-slider__index ">{`${i + 1} / ${images.length}`}</span>
                    </div>
                  </div>
                )
              })}
          </Fade>
        </div>
      </div>
    )
  }
  componentDidMount() {
    if (typeof window === 'undefined') {
      return
    }
    this.onResize()
    window.addEventListener(
      'resize',
      _debounce(() => {
        this.onResize()
      }, 1000)
    )
  }
  componentWillUnmount() {
    window.removeEventListener(
      'resize',
      _debounce(() => {
        this.onResize()
      }, 1000)
    )
  }
}

export default ImageSlider
